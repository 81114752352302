.layout {
  height: 100vh;
  min-width: 1200px;
}

.content {
  height: calc(100vh - 4rem);
  overflow-y: scroll;
}

.innerContent {
  min-height: calc(100vh - 8rem);
  padding: 1rem;
}

.footer {
  height: 4rem;
  text-align: center;
}
