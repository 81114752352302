.sider {
  user-select: none;
  background: red;
}

.brandLogoContainer {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.brandLogo {
  width: 80%;
  height: 2.5rem;
  /* background: #ffffff33; */
  border-radius: 8px;
  object-fit: contain;
}

.menu {
  height: calc(100vh - 7rem);
  overflow-y: auto;
}
