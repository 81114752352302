.container {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeIcon {
  margin-inline-end: 0.5rem;
}
