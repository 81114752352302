.header {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.trailing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
