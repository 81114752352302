* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.ant-layout-sider-trigger {
  position: static !important;
  background: #301e65 !important;
}

.ant-layout-sider-children {
  background: #24174b;
}

.ant-menu-dark {
  background: #24174b;
}

.drivers-list .ant-list-items {
  height: 31.3rem;
  overflow-x: auto;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #12ccaa transparent;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 7px;
  width: 7px;
}
*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

*::-webkit-scrollbar-track:active {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #12ccaa;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #12ccaa;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #12ccaa;
}

.db-frame {
  width: calc(100% + 32px);
  height: calc(100vh - 64px);
  margin: -16px;
}
